var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.incidents, function(incident) {
        return _c(
          "li",
          { key: incident.id },
          [
            incident.disappearsAt === null || incident.disappearsAt > _vm.now
              ? _c(
                  "router-link",
                  {
                    staticClass:
                      "block focus:outline-none focus:bg-gray-100 hover:bg-gray-100",
                    attrs: {
                      to: {
                        name: "incident-detail",
                        params: { id: "" + incident.id }
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-between items-center ml-4 py-3 pr-4\n          border-b border-blue-500 max-w-full"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex flex items-center w-3/4" },
                          [
                            _c("span", {
                              staticClass:
                                "w-7 h-7 rounded-full mr-2 flex-none",
                              class:
                                incident.resolvedAt !== null
                                  ? "bg-gray-500"
                                  : "bg-blue-500",
                              style:
                                incident.resolvedAt === null &&
                                incident.incidentTypes.length > 0
                                  ? "background-color: " +
                                    incident.incidentTypes[0].color
                                  : ""
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-col leading-tight w-3/4"
                              },
                              [
                                _c("p", {
                                  staticClass: "font-semibold truncate",
                                  class: {
                                    "line-through": incident.resolvedAt !== null
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      incident.title !== null
                                        ? incident.title
                                        : incident.incidentTypes[0].name
                                    )
                                  }
                                }),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-xs uppercase text-gray-500 truncate max-w-full"
                                  },
                                  [
                                    !incident.createdAt.isSame(_vm.now, "day")
                                      ? _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              incident.createdAt.format("L")
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    incident.createdAt.isSame(_vm.now, "day")
                                      ? _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              incident.createdAt.format("LT")
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          " • " +
                                            (incident.assignedUsers.length === 0
                                              ? _vm.$t(
                                                  "incidents_list.label_unassigned"
                                                )
                                              : incident.assignedUsers
                                                  .map(function(user) {
                                                    return user.shortName
                                                  })
                                                  .join(" • "))
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-1/4 flex justify-end items-center ml-3"
                          },
                          [
                            incident.assignedUsers.length > 0 ||
                            !incident.canAssign
                              ? _c("img", {
                                  staticClass: "h-3 w-3",
                                  attrs: {
                                    src: require("@/assets/images/ic_chevron_right_blue.svg")
                                  }
                                })
                              : _c("router-link", {
                                  staticClass:
                                    "btn-blue rounded-full py-1 px-3 uppercase text-xs truncate max-w-full",
                                  attrs: {
                                    to: {
                                      name: "incident-assign",
                                      params: { id: "" + incident.id }
                                    }
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("incidents_list.button_assign")
                                    )
                                  }
                                })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      }),
      _vm.incidents.length === 0
        ? _c("li", {
            staticClass: "ml-4 pr-4 py-3 text-md text-gray-900",
            domProps: {
              textContent: _vm._s(_vm.$t("incidents_list.label_empty_list"))
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }