<template>
  <ul>
    <li
      v-for="incident in incidents"
      :key="incident.id">
      <router-link
        v-if="incident.disappearsAt === null || incident.disappearsAt > now"
        :to="{name: 'incident-detail', params: {id: `${incident.id}`}}"
        class="block focus:outline-none focus:bg-gray-100 hover:bg-gray-100"
      >
        <div
          class="flex justify-between items-center ml-4 py-3 pr-4
            border-b border-blue-500 max-w-full"
        >
          <div class="flex flex items-center w-3/4">
            <span
              :style="incident.resolvedAt === null && incident.incidentTypes.length > 0 ?
                `background-color: ${incident.incidentTypes[0].color}`
                : ''"
              class="w-7 h-7 rounded-full mr-2 flex-none"
              :class="incident.resolvedAt !== null ? 'bg-gray-500': 'bg-blue-500'"
            ></span>
            <div class="flex flex-col leading-tight w-3/4">
              <p
                v-text="incident.title !== null ? incident.title : incident.incidentTypes[0].name"
                class="font-semibold truncate"
                :class="{'line-through': incident.resolvedAt !== null}"
              ></p>
              <p class="text-xs uppercase text-gray-500 truncate max-w-full">
                <span
                  v-if="!incident.createdAt.isSame(now, 'day')"
                  v-text="incident.createdAt.format('L')"
                ></span>
                <span
                  v-if="incident.createdAt.isSame(now, 'day')"
                  v-text="incident.createdAt.format('LT')"
                ></span>
                <span
                  v-text="` • ${incident.assignedUsers.length === 0 ?
                    $t('incidents_list.label_unassigned') :
                    incident.assignedUsers.map(user => user.shortName).join(' • ')}`">
                </span>
              </p>
            </div>
          </div>
          <div class="w-1/4 flex justify-end items-center ml-3">
            <img
              v-if="incident.assignedUsers.length > 0 || !incident.canAssign"
              src="@/assets/images/ic_chevron_right_blue.svg"
              class="h-3 w-3"
            >
            <router-link
              v-else
              :to="{name: 'incident-assign', params: {id: `${incident.id}`}}"
              class="btn-blue rounded-full py-1 px-3 uppercase text-xs truncate max-w-full"
              v-text="$t('incidents_list.button_assign')"
            ></router-link>
          </div>
        </div>
      </router-link>
    </li>
    <li
      v-if="incidents.length === 0"
      v-text="$t('incidents_list.label_empty_list')"
      class="ml-4 pr-4 py-3 text-md text-gray-900"
    ></li>
  </ul>
</template>

<script>
  import time from '@/mixins/time';

  export default {
    name: 'IncidentsList',
    mixins: [time],
    props: {
      incidents: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>
